body,
html {
    margin: 0;
    font-family: "Roboto", "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    height: 100%;
}

@media all and (min-width: 480px) and (max-width: 1024px) {
    body,
    html {
        font-size: 14px;
    }
}

@media (min-width: 1024px) {
    body,
    html {
        font-size: 16px;
    }
}

* {
    box-sizing: border-box;
}

@font-face {
    font-family: "Druk Wide Bold";
    src: url("./fonts/Druk\ Wide\ Bold.woff") format("woff");
}

.carousel-root {
    width: 100%;
}

h1,
h2,
h3,
h4 {
    display: inline;
    padding: 0;
    margin: 0;
    font-size: inherit;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}
